import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

export default class TourListController {
  constructor () {
    this.accessToken = process.env.API_ACCESS_TOKEN

    this.cityPageUrl = process.env.CITY_PAGE_API
    this.pagesURL = `${this.cityPageUrl}sites/walksofitaly/page/`
    this.salespageURL = `${this.cityPageUrl}sites/walksofitaly/salespage/`
  }

  /**
   * GET sale pages from contentful through REST endpoint
   * @param {String} path - one of [blackfriday, ...]
   * @returns {Object} - if success, return Object with this structure:
   * { path: 'blackfriday22', result: {...data} }
   * path: name of the page requested,
   * results: response from response.data
   */
  async getSalesPage (path) {
    const isInLocalStorage = this.getFromLocalStorage(`sales-${path}`)
    if (isInLocalStorage) {
      this.getSalesPageHelper(path)
      return isInLocalStorage
    }
    return await this.getSalesPageHelper(path)
  }

  async getSalesPageHelper (path) {
    const pageurl = `${this.salespageURL}${path}`
    try {
      const response = await axios({
        url: pageurl,
        headers: { Authorization: 'Bearer ' + this.accessToken }
      })
      if (response.data) {
        const result = response.data
        const formatedReponse = {
          path: result.url,
          result: result
        }
        process.browser && Vue.localStorage.set(`sales-${path}`, JSON.stringify(formatedReponse))
        return formatedReponse
      } else {
        console.warn(response)
        process.browser && Vue.localStorage.set(`sales-${path}`, JSON.stringify(null))
        // throw new Error(`Error: ${path.toUpperCase()} data not found`)
      }
    } catch (e) {
      console.warn(response)
      throw new Error(`Error: ${path.toUpperCase()} data not found`)
    }
  }

  async getPopup () {
    const url = `${this.cityPageUrl}sites/walksofitaly/popup`
    try {
      const response = await axios.get(url, {
        headers: { Authorization: 'Bearer ' + this.accessToken }
      })
      if (response.data) {
        return response.data
      } else {
        return {}
      }
    } catch (e) {
      return {}
    }
  }

  async getSalesBanner () {
    const url = `${this.cityPageUrl}sites/walksofitaly/banner`
    try {
      const response = await axios.get(url, {
        headers: { Authorization: 'Bearer ' + this.accessToken }
      })
      if (response.data) {
        return response.data
      } else {
        return null
      }
    } catch (e) {
      return null
    }
  }


  /**
   * GET static pages from contentful through REST endpoint
   * @param {String} path - one of [terms, faq, ...]
   * @returns {Object} - if success, return Object with this structure:
   * { path: 'faq', result: {...data} }
   * path: name of the page requested,
   * results: response from response.data
   */
  async getStaticPage (path) {
    const isInLocalStorage = this.getFromLocalStorage(path)
    if (isInLocalStorage) {
      this.getStaticPageHelper(path)
      return isInLocalStorage
    }
    return await this.getStaticPageHelper(path)
  }

  async getStaticPageHelper (path) {
    const pageurl = `${this.pagesURL}${path}`
    try {
      const response = await axios({
        url: pageurl,
        headers: { Authorization: 'Bearer ' + this.accessToken }
      })
      if (response.data) {
        const result = response.data
        const formatedReponse = {
          path: result.url,
          result: result
        }
        process.browser && Vue.localStorage.set(path, JSON.stringify(formatedReponse))
        return formatedReponse
      } else {
        console.warn(response)
        throw new Error(`Error: ${path.toUpperCase()} data not found`)
      }
    } catch (e) {
      console.warn(response)
      throw new Error(`Error: ${path.toUpperCase()} data not found`)
    }
  }
  /**
   * @param {Strin} endpoint - try to see if 'endpoint' is already in local storage
   * @returns {Boolean, Object} - if found, parse and return it, else return false
   */
  getFromLocalStorage (endpoint) {
    if (process.browser) {
      const localStorage = Vue.localStorage.get(endpoint)
      return (localStorage) ? JSON.parse(localStorage) : false
    }
    return false
  }
}
