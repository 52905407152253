import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _5c571d95 = () => interopDefault(import('../pages/about/index.vue' /* webpackChunkName: "pages/about/index" */))
const _87ce6eb2 = () => interopDefault(import('../pages/booking-manage/index.vue' /* webpackChunkName: "pages/booking-manage/index" */))
const _46528498 = () => interopDefault(import('../pages/californiaprivacy/index.vue' /* webpackChunkName: "pages/californiaprivacy/index" */))
const _392c26de = () => interopDefault(import('../pages/cancellation/index.vue' /* webpackChunkName: "pages/cancellation/index" */))
const _6940ab28 = () => interopDefault(import('../pages/contact/index.vue' /* webpackChunkName: "pages/contact/index" */))
const _761cecbe = () => interopDefault(import('../pages/cookies-policy/index.vue' /* webpackChunkName: "pages/cookies-policy/index" */))
const _6b9a551e = () => interopDefault(import('../pages/faq/index.vue' /* webpackChunkName: "pages/faq/index" */))
const _aaa4de2a = () => interopDefault(import('../pages/health-safety/index.vue' /* webpackChunkName: "pages/health-safety/index" */))
const _331529d0 = () => interopDefault(import('../pages/not-found/index.vue' /* webpackChunkName: "pages/not-found/index" */))
const _2c1bdce4 = () => interopDefault(import('../pages/payment/index.vue' /* webpackChunkName: "pages/payment/index" */))
const _11808890 = () => interopDefault(import('../pages/privacy/index.vue' /* webpackChunkName: "pages/privacy/index" */))
const _5292dbd9 = () => interopDefault(import('../pages/signup-confirm/index.vue' /* webpackChunkName: "pages/signup-confirm/index" */))
const _64676a25 = () => interopDefault(import('../pages/terms-of-use/index.vue' /* webpackChunkName: "pages/terms-of-use/index" */))
const _b35ed9e2 = () => interopDefault(import('../pages/terms/index.vue' /* webpackChunkName: "pages/terms/index" */))
const _4dad8c29 = () => interopDefault(import('../pages/travel-agents/index.vue' /* webpackChunkName: "pages/travel-agents/index" */))
const _4dd607dd = () => interopDefault(import('../pages/offers/_offer/index.vue' /* webpackChunkName: "pages/offers/_offer/index" */))
const _2504b152 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _579c77dc = () => interopDefault(import('../pages/_city/index.vue' /* webpackChunkName: "pages/_city/index" */))
const _42cb753a = () => interopDefault(import('../pages/_city/_tour/index.vue' /* webpackChunkName: "pages/_city/_tour/index" */))
const _0283fffa = () => interopDefault(import('../pages/_city/_tour/reviews.vue' /* webpackChunkName: "pages/_city/_tour/reviews" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about/",
    component: _5c571d95,
    pathToRegexpOptions: {"strict":true},
    name: "about"
  }, {
    path: "/booking-manage/",
    component: _87ce6eb2,
    pathToRegexpOptions: {"strict":true},
    name: "booking-manage"
  }, {
    path: "/californiaprivacy/",
    component: _46528498,
    pathToRegexpOptions: {"strict":true},
    name: "californiaprivacy"
  }, {
    path: "/cancellation/",
    component: _392c26de,
    pathToRegexpOptions: {"strict":true},
    name: "cancellation"
  }, {
    path: "/contact/",
    component: _6940ab28,
    pathToRegexpOptions: {"strict":true},
    name: "contact"
  }, {
    path: "/cookies-policy/",
    component: _761cecbe,
    pathToRegexpOptions: {"strict":true},
    name: "cookies-policy"
  }, {
    path: "/faq/",
    component: _6b9a551e,
    pathToRegexpOptions: {"strict":true},
    name: "faq"
  }, {
    path: "/health-safety/",
    component: _aaa4de2a,
    pathToRegexpOptions: {"strict":true},
    name: "health-safety"
  }, {
    path: "/not-found/",
    component: _331529d0,
    pathToRegexpOptions: {"strict":true},
    name: "not-found"
  }, {
    path: "/payment/",
    component: _2c1bdce4,
    pathToRegexpOptions: {"strict":true},
    name: "payment"
  }, {
    path: "/privacy/",
    component: _11808890,
    pathToRegexpOptions: {"strict":true},
    name: "privacy"
  }, {
    path: "/signup-confirm/",
    component: _5292dbd9,
    pathToRegexpOptions: {"strict":true},
    name: "signup-confirm"
  }, {
    path: "/terms-of-use/",
    component: _64676a25,
    pathToRegexpOptions: {"strict":true},
    name: "terms-of-use"
  }, {
    path: "/terms/",
    component: _b35ed9e2,
    pathToRegexpOptions: {"strict":true},
    name: "terms"
  }, {
    path: "/travel-agents/",
    component: _4dad8c29,
    pathToRegexpOptions: {"strict":true},
    name: "travel-agents"
  }, {
    path: "/offers/:offer?/",
    component: _4dd607dd,
    pathToRegexpOptions: {"strict":true},
    name: "offers-offer"
  }, {
    path: "/",
    component: _2504b152,
    pathToRegexpOptions: {"strict":true},
    name: "index"
  }, {
    path: "/:city/",
    component: _579c77dc,
    pathToRegexpOptions: {"strict":true},
    name: "city"
  }, {
    path: "/:city/:tour/",
    component: _42cb753a,
    pathToRegexpOptions: {"strict":true},
    name: "city-tour"
  }, {
    path: "/:city/:tour/reviews/",
    component: _0283fffa,
    pathToRegexpOptions: {"strict":true},
    name: "city-tour-reviews"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
