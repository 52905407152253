import axios from 'axios'
export default class ReviewController {
  constructor () {
    this.feedbackURL = process.env.FEEDBACK_API,
    this.cityPageUrl = process.env.CITY_PAGE_API,
    this.accessToken = process.env.API_ACCESS_TOKEN
  }

  /**
   * single tour's reviews with review text
   * @param {string} tourUrl 
   * @param {number} offset 
   * @param {number} limit 
   * @returns 
   */
  async getTourReviews(tourUrl, offset, limit) {
    const payload = {
      offset,
      limit,
    }
    const response = await axios({
      method: 'get',
      url: this.feedbackURL + `tours/${tourUrl}/feedbacks`,
      params: payload,
      headers: { Authorization: 'Bearer ' + this.accessToken }
    })
    if (response.data) {
      var reviewData = response.data.records
      var reviews = []
      for (var index in reviewData) {
        reviews.push({
          reviewTitle: reviewData[index].eventTitle,
          reviewContent: reviewData[index].tourReview,
          customerName: reviewData[index].clientFirstName + ' ' + reviewData[index].clientLastName,
          date: reviewData[index].feedbackDate,
          numberOfStars: reviewData[index].eventRating
        })
      }
      return reviews
    } else {
      return []
    }
  }

  /**
   * single tour's review summary
   * @param {string} tourUrl 
   * @returns 
   */
  async getTourReviewStat(tourUrl) {
    const defaultReviewStatus = {
      feedbackCount: 0,
      feedbackAverage: 0,
      starsGroup: {},
      feedback_count: 0,
      feedback_average: 0,
      feedback_count_five_stars: 0,
      feedback_count_four_stars: 0,
      feedback_count_three_stars: 0,
      feedback_count_two_stars: 0,
      feedback_count_one_star: 0,
    };
    try {
      const response = await axios.get(
        this.feedbackURL + `tours/${tourUrl}/feedbacks/stars`,
        {
          params: { stars_above: 0 },
          headers: { Authorization: 'Bearer ' + this.accessToken },
        }
      );
      if (response.data) {
        const { totalReviews, starsGroup, average } = response.data;
        const reviewStatus = {
          feedbackAverage: average,
          feedbackCount: totalReviews,
          starsGroup,
          feedback_count: totalReviews,
          feedback_average: average,
          feedback_count_five_stars: starsGroup['5'],
          feedback_count_four_stars: starsGroup['4'],
          feedback_count_three_stars: starsGroup['3'],
          feedback_count_two_stars: starsGroup['2'],
          feedback_count_one_star: starsGroup['1'],
        };
        return reviewStatus;
      } else {
        return defaultReviewStatus;
      }
    } catch (err) {
      return defaultReviewStatus;
    }
  }

  /**
   * single tour's similar tours' review summary
   * @param {string} tourUrl 
   * @returns 
   */
  async getSimilarToursStars(tourUrl) {
    const res = {};
    try {
      const response = await axios.get(this.feedbackURL +`tour/${tourUrl}/feedbacks/stars/summary`, {
        headers: { Authorization: 'Bearer ' + this.accessToken },
      });
      if (response.data) {
        // console.log('similar ==> ', response.data);
        response.data.forEach((star) => {
          res[star.tourUniqueId] = {
            eventId: star.tourUniqueId,
            feedbackCount: star.totalReviews,
            feedbackAverage: star.average,
          };
        });
      }
      return res;
    } catch (err) {
      return res;
    }
  }

  /**
   * reviews stat (total number, avarage score) for homepage or city page
   * @param {string} cityUrl 
   * @returns 
   */
  async getCityReviewSummary(cityUrl) {
    const summary = {};
    try {
      if (cityUrl === 'homepage') {
        const response = await axios.get(this.feedbackURL +`city/${cityUrl}/feedbacks/stars/summary`, {
          headers: { Authorization: 'Bearer ' + this.accessToken },
        });
        if (response.data) {
          response.data.forEach((stat) => {
            summary[stat.tourUniqueId] = stat;
          });
        }
      } else {
          const response = await axios({
            url: `${this.cityPageUrl}sites/walksofitaly/city/${cityUrl}/tours/review/summary`,
            headers: { Authorization: `Bearer ${this.accessToken}` },
          })
          return response?.data
      }
      
      return summary;
    } catch (error) {
      return summary;
    }
  }

  async getHomepageReviewState () {
    try {
      const response = await axios.get(`${this.cityPageUrl}sites/walksofitaly/newhomepage/review/stat`, {
        headers: { Authorization: 'Bearer ' + this.accessToken },
      });
      if (response.data) {
        return response.data
      }
    } catch (error) {
      return {};
    }
  }
}

