import axios from 'axios'

export default class HomeController {
  constructor () {
    this.cityPageUrl = process.env.CITY_PAGE_API
    this.accessToken = process.env.API_ACCESS_TOKEN
  }

  async getNewHomepageData () {
    try {
      const response = await axios({
        url: this.cityPageUrl + 'sites/walksofitaly/newhomepage',
        headers: { Authorization: 'Bearer ' + this.accessToken }
      })
      if (response.status == 200) {
        return response.data
      }
    } catch (err) {
      throw new Error(err)
    }
  }
}
